.breadcrumbs li {
  color: rgba(30, 110, 190, 0.5);
}

.breadcrumbs__crumb {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.breadcrumbs__crumb_prev {
  color: rgba(30, 110, 190, 0.5);
  text-decoration: none;
  cursor: pointer;
}
.breadcrumbs__crumb_prev:hover {
  text-decoration: underline;
}

.breadcrumbs__crumb_current {
  color: rgb(30, 110, 190);
}