.organizationsFieldsModal {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.organizationsFieldsModal .form {
    position: relative;
    width: 50%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    outline: none;
    height: 92%;
    border-radius: 4px;
}

.form {
    display: flex;
    flex-direction: column;
}

.form__header {
    height: 8%;
    width: 100%;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.form__headerTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    color: #000000;
}

.form__main {
    overflow-y: auto;
    padding-left: 16px;
}

.form__main::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
}

.form__main::-webkit-scrollbar-thumb {
    background: #767676;
    border-radius: 5px;
    box-shadow: inset 1px 1px 10px #f3faf7;
}

.form__main::-webkit-scrollbar-thumb:hover {
    background-color: #253861;
}

.form__sectionsContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.form__section {
    display: flex;
    flex-direction: column;
}

.form__sectionTitleWr {
    display: flex;
    align-items: center;
    column-gap: 8px;
}

.form__sectionTitle {
    padding: 8px 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #333333;
    background-color: rgba(30, 110, 190, 0.25);
    cursor: pointer;
}

.form__sectionContent {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.form__chackboxBlock {
    pointer-events: none;
}

.form__toggler {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 24px;
    width: 24px;
    min-height: 24px;
    height: 24px;
}

.form__toggler>* {
    padding: 0 !important;
}

.form__checkboxLabel {
    margin: 10px;
    font-size: 16px;
}

.form__checkbox_unchecked {
    opacity: 0;
}

.form__footer {
    width: 100%;
    padding: 16px;
    display: flex;
    justify-content: space-between;
}

.form__footerBtnsWr {
    display: flex;
    column-gap: 16px;
}

.form__saveBtn {
    height: 39px;
    width: 200px;
    background-color: #1E6EBE;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
    color: #FFFFFF;
}

.form__saveBtn:hover {
    cursor: pointer;
}