.head {
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    text-transform: uppercase;
    color: #000000;
}
.referenceListModal {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modalForm {
    position: relative;
    width: 600px;
    height: fit-content;
    padding: 35px;
    padding-top: 25px;
    background: #FFFFFF;
    border: 1px solid rgba(30, 110, 190, 0.1);
    box-shadow: 0px 4px 8px rgba(30, 110, 190, 0.25);
    font-family: 'Roboto';
}

.btns {
    margin-top: 25px;
}

.btns button {
    background: #1E6EBE;
    border-radius: 0px;
    width: 200px;
    height: 45px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    border: none;
    outline: none;
    font-family: 'Roboto';
    cursor: pointer;
}

.btns button:first-child {
    margin-right: 30px;
}

.btns button:disabled {
    cursor: default;
    background-color: rgba(30, 110, 190, 0.25);
}

.iconButton {
    position: absolute;
    right: 25px;
    top: 25px;
}

.wrapperFields {
    display: flex;
    width: 100%;
    margin-top: 29px;
    margin-bottom: 24px;
}

.field {
    height: 70px;
    margin-right: 40px;
}

.field input {
    width: 100%;
    height: 50px;
    background: #F6F6F6;
    outline: none;
    border: none;
    border-radius: 0;
    padding: 15px;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: #333333;
}

.field input::placeholder {
    color: rgba(60, 60, 67, 0.3);
}

.field p {
    margin-bottom: 2px;
    font-weight: 400;
    font-size: 13px;
    line-height: 120%;
    color: #000000;
}

.wrapperFields .field input {
    width: 380px;
}
