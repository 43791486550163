.form {
    margin: 20px 0 50px 0;
    display: flex;
    flex-direction: column;
    /* row-gap: 58px; */
}

.form__block {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
}

.form__blockHeadlineWr {
    padding: 10px;
    margin-bottom: 10px;
    width: fit-content;
    background: rgba(30, 110, 190, 0.25);
}

.form__switcherBlock {
    margin-bottom: 10px;
}

.form__blockBtn {
    width: 200px;
    height: 39px;
    border: none;
    background: #1E6EBE;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
    color: #FFFFFF;
    cursor: pointer;
}

.form__blockHeadline {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: #000000;
    text-transform: uppercase;
}

.form__switcherLabel {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #3C3C43;
    user-select: none;
    cursor: pointer;
}

.form__inputBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.form__inputLabel {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #3C3C43;
}

.form__input {
    padding: 8px;
    min-width: 288px;
    width: 288px;
    height: 35px;
    background-color: #EEF1F3;
    border: none;
    border-radius: 5px;
    outline: none;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #3C3C43;
}

.form__btnsGroup {
    display: flex;
    column-gap: 16px;
}

.form__btn {
    width: 250px;
    height: 55px;
    background-color: #1E6EBE;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    cursor: pointer;
}

.form__blockInfo {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin-bottom: 30px;
}

.form__subhealine {
    text-transform: uppercase;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #3C3C43;
}

.form__time {
    padding: 10px;
    background-color: #EEF1F3;
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #3C3C43;
}