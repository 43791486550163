.containerTable {
    position: relative;
    margin-top: 5px;
    border-radius: 0 !important;
    box-shadow: none !important;
}

.containerTableHeightLoading {
    height: 600px;
}

.tableHeader {
    background: rgba(30, 110, 190, 0.1);
}

.tableCell {
    border: none !important;
    color: #1E6EBE !important;
}

.buttonContainer {
    display: flex;
    margin-top: 40px;
    margin-bottom: 60px;
}

.buttonContainer button, .buttonContainer label {
    width: 250px !important;
    height: 55px !important;
    border-radius: 0 !important;
    text-transform: none !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    background: #1E6EBE !important;
}

.buttonContainer button {
    margin-right: 16px;
}

.confirmationModal {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.confirmationModalInnerContainer{
    width: 432px;
    height: 240px;
    margin-top: 20;
    background-color: white;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 10px;
    justify-content: center;
    align-items: center;
    padding: 0 73px;
    border-radius: 0;
}

.confirmationModalInnerContainer p {
    font-weight: 600;
    font-size: 20px;
    line-height: 120%;
    text-align: center;
    margin-bottom: 25px;
}

.confirmButton{
    width: 250px;
    height: 55px;
    border-radius: 0 !important;
    text-transform: none !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 24px !important;
}

.iconButton{
    position: absolute;
    right: 10px;
    top: 10px;
}

.containerSubmenu {
    position: relative;
}

.containerSubmenu button {
    display: flex;
    justify-content: center;
    align-items: top;
    cursor: pointer;
    font-size: 18px;
    padding: 5px;
    font-weight: bold;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: none;
    background-color: inherit;
}

.containerSubmenu button:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.submenu {
    position: absolute;
    width: 210px;
    right: 50px;
    top: -50px;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    z-index: 5;
    padding-top: 5px;
    padding-bottom: 5px;
}

.submenu button {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 120% !important;
    color: #333333 !important;
    width: 100%;
    border-radius: 0;
    justify-content: start;
    padding-left: 15px;
}

.submenu button:hover {
    background: rgba(204, 223, 228, 0.2);
}

.submenu button:last-child img {
    margin-right: 13px;
}

.submenu button:last-child {
    margin-top: 5px;
}

.containerFooter {
    display: flex;
    align-items: center;
}

.pagination {
    margin-left: 13px;
    opacity: 0.8 !important;
}
