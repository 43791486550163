.tableHeader {
    background: rgba(30, 110, 190, 0.1);
}

.tableHeader b {
    color: #1E6EBE;
}

.cell_checkbox {
    width: 40px !important;
}

.header__cell {
    background-color: rgb(240, 246, 255) !important;   
}