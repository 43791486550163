.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 17px;
}

.form__field {
    display: flex;
    flex-direction: column;
}

.form__label {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #000000;
}

.form__input {
    padding: 16px;
    width: 380px;
    height: 50px;
    background: #F6F6F6;
    outline: none;
    border: none;
    border: 1px solid #F6F6F6;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
}

.form__input_error {
    border: 1px solid #FF3B30;
}

.form__error {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #FF3B30;
}

.form__submitBtn {
    padding: 16px 0;
    width: 250px;
    background-color: #1E6EBE;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    cursor: pointer;
}

.form__submitBtn:hover {
    background-color: #6997C6;
}

.form__submitBtn:disabled {
    background-color: #1E6EBE;
    cursor: default;
}

.form__input:-webkit-autofill,
.form__input:-webkit-autofill:hover, 
.form__input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #F6F6F6 inset;
}
