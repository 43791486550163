.organizationsFieldsModal {
    width: 750px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.form {
    position: relative;
    width: 750px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    outline: none;
    height: 92%;
    border-radius: 4px;
}
.form__header {
    height: 8%;
    width: 100%;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.form__main {
    width: 100%;
    overflow-y: auto;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
}
.textMain {
    line-height: 18px;
    padding: 8px 10px;
    width: fit-content;
}
.textMain::first-letter {
    text-transform: capitalize;
}

.form__main::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
}

.form__main::-webkit-scrollbar-thumb {
    background: #767676;
    border-radius: 5px;
    box-shadow: inset 1px 1px 10px #f3faf7;
}

.form__main::-webkit-scrollbar-thumb:hover {
    background-color: #253861;
}

.form__fieldsGroup {
    width: 100%;
    margin-bottom: 20px;
}
.form__fields {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 30px;
    row-gap: 15px;
    align-items: end;
}
.form__btnsGroup {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.btnSubmit {
    height: 39px;
    width: 200px;
    background-color: #1E6EBE;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
    color: #FFFFFF;
}

.btnReset {
    width: 230px;
    background-color: #ffffff;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
    color: #1E6EBE;
    border:1px solid  #1E6EBE;
    cursor: pointer;
    height: 39px;
    margin-right: 15px;
}

.btnSubmit:hover, .btnReset:hover {
    cursor: pointer;
}

.btnSubmit:disabled, .btnReset:disabled {
    cursor: default;
}
