.containerTextInput {
    width: 210px;
    margin-bottom: 12px;
}

.MuiOutlinedInput-notchedOutline {
    border-radius: 0px !important;
}

.textInputLabel {
    width: 300px !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    color: '#333333' !important;
    line-height: 120% !important;
    white-space: normal !important;
}

.input {
    border: 0.5px solid #4F4F4F !important;
}

.input input::placeholder {
    border: 0.5px solid #4F4F4F !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 12px !important;
    color: #000000 !important;
    /* opacity: 0.5 !important; */
}

.fieldset {
    display: flex;
    flex-direction: column;
}

.fieldset__label {
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 12px;
    color: #333333;
}

.fieldset__input {
    padding: 7.5px 8px;
    border: 0.5px solid #4F4F4F;
    outline: none;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 12px;
    color: #000000;
    box-sizing: border-box;
    height: 32px;
}

.fieldset__input::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #000000;
    opacity: 0.5;
}

.fieldset__input:-webkit-autofill,
.fieldset__input:-webkit-autofill:hover, 
.fieldset__input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}
