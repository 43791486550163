.section {
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.sort__container {
    display: flex;
    align-items: center;
    column-gap: 20px;
}

.modalBtn__block {
    display: flex;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
}

.filterTable__container {
    display: flex;
    column-gap: 20px;
    max-width: 1300px !important;
}
.cont {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 8px rgba(30, 110, 190, 0.25);
    border-radius: 4px;
    max-height: fit-content;
    height: fit-content;
}
.TableContainer {
    max-height: 68vh;
    max-width: 1300px !important;
}

.TableContainer::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    background-color: transparent;
}

.TableContainer::-webkit-scrollbar-thumb {
    background: #767676;
    border-radius: 5px;
    box-shadow: inset 1px 1px 10px #f3faf7;

}

.TableContainer::-webkit-scrollbar-thumb:hover {
    background-color: #253861;
    cursor: pointer;
}
.table {
    max-width: 1300px !important;
    overflow-x: auto !important;
}
.tableHeader {
    background: rgba(30, 110, 190, 1);
}

.tableHeader b {
    color: #1E6EBE;
}

.footer {
    display: flex;
    align-items: center;
    column-gap: 106px;
    padding: 20px;
    justify-content: space-between;
}

.footer__btnsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 14px;
}

.footer__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 39px;
    background: #1e6ebe;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
    color: #ffffff;
    cursor: pointer;
}

.table__row_head {
    background-color: rgba(30, 110, 190, 0.1);
}

.table__cellText_head {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
    color: rgb(30, 110, 190) !important;
}

.cell__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
}
.cell__wrapper:hover {
    position: static;
}

.link {
    position: relative;
    font-weight: 700;
    font-size: 13px;
    color: #030DFF;
}

.link:hover {
    text-decoration: underline;
    cursor: pointer;
}

.row_children {
    background-color: rgba(0, 95, 120, 0.05) !important;
}

.containerModalRow {
    position: absolute;
    left: 80px;
    top: -30px;
    width: 185px;
    height: 60px;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    z-index: 5;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.containerModalRow a {
    flex: 1;
    font-weight: 400;
    line-height: 120%;
    color: #333333;
    padding: 0 20px;
    display: flex;
    height: 30px;
    align-items: center;
    font-size: 13px;
}

.containerModalRow a:hover {
    background-color: #F5F9FA;
}

.cell__wrapper_horizontal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
}
.icon:hover {
    background-color: rgb(200, 200, 200);
    border-radius: 50%;
    cursor: pointer;
}

.cell_checkbox {
    width: 40px !important;
}

.cell_ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 250px;
    height: 30px;
}