.modal__wrapper {
    height: 100vh;
    display: flex;
    align-items: center;
}

.modal {
    padding: 35px;
    width: 1300px;
    background-color: #fff;
    vertical-align: baseline;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.modal__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.modal__closeBtn {
    height: 30px;
    width: 30px;
    background-color: transparent;
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal__closeBtn:hover {
    cursor: pointer;
    background-color: rgb(236, 236, 236);
}

.modal__switcherWrapper {
    display: flex;
    align-items: center;
}
.modal__inputsWrapper {
    margin: 10px 0 30px 0;
}
.modal__inputBlock {
    display: flex;
    flex-direction: column;
    row-gap: 1px;
}
.modal__inputLabel {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #000000;
}

.modal__input {
    padding: 15px 16px;
    background: #F6F6F6;
    outline: none;
    border: 1px solid #F6F6F6;
}

.modal__input_error {
    border: 1px solid #FF3B30;
}

.modal__error {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #FF3B30;
}

.modal__btnsWrapper {
    display: flex;
    column-gap: 16px;
}

.modal__btn {
    width: 250px;
    height: 55px;
    background: #1E6EBE;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}


.modal__btn:hover {
    cursor: pointer;
}

.modal__btn[disabled]{
    cursor: default;
    background-color: rgba(30, 110, 190, 0.25);
}

.unchecked {
    opacity: 0;
}
.form__toggler {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 24px;
    width: 24px;
    min-height: 24px;
    height: 24px;
    margin: 6px;
}

.wrap__checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.checkboxIcon {
    display: inline-block;
    width: 24px;
    height: 24px;
    border: 1px solid #333333;
    background-color: #fff;
}

.checkboxIconChecked {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border: 1px solid #333333;
}

.modal__title {
    text-transform: uppercase;
    font-size: 20px;
}
