.wrapper {
  margin-top: 150px;
  margin-bottom: 20px;
}

.title {
  text-align: center;
  font-size: 30px;
  font-weight: 700;
}

.subtitle {
  text-align: center;
  font-size: 20px;
  max-width: 620px;
  margin-top: 10px;
}

.backgroundText {
  background: rgba(30, 110, 190, 0.25);
  color: #333333;
  display: inline-block;
  margin-top: 25px;
  font-weight: 500;
  padding-left: 5px;
  font-size: 20px;
}

.subText {
  font-size: 18px;
  margin-top: 5px;
  line-height: 130%;
}

.infoText {
  margin-top: 30px;
  font-size: 16px;
}

.buttonStart {
  margin-top: 70px;
  text-align: center;
}

.line {
  margin-top: 40px;
  text-align: center;
}

.formWrap {
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  width: 380px;
}

.subtitleWrap {
  display: flex;
  justify-content: center;
  max-width: 100%;
}

.wrapShowEmail {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
}

.wrapShowEmail div {
  color: #fff;
}

.emailText {
  margin-bottom: 4px;
  font-size: 13px;
}

.secretBtn {
  position: absolute;
  top: -18px;
  height: 20px;
  width: 20px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.loginBtn {
  padding: 10px 40px;
  background-color: #1E6EBE;
  border: none;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  cursor: pointer;
}

.sendBtn {
  padding: 10px 0;
  width: 385px;
  background-color: #1E6EBE;
  border: none;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  cursor: pointer;
}
.sendBtn:disabled {
  cursor: default;
}

.form {
  width: 385px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 17px;
}

.form__field {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.form__label {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
}

.form__input {
  padding: 16px;
  width: 385px;
  height: 50px;
  background: #F6F6F6;
  outline: none;
  border: none;
  border: 1px solid #F6F6F6;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.form__input_error {
  border: 1px solid #FF3B30;
}

.form__error {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #FF3B30;
}

.form__input:-webkit-autofill,
.form__input:-webkit-autofill:hover, 
.form__input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #F6F6F6 inset;
}
