.nav {
    display: flex;
    justify-content: center;
}

.text {
    font-weight: 400;
    font-size: 9px;
    line-height: 24px;
    color: #333333;
    opacity: 0.5 !important;
    text-align: center;
}