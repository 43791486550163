.containerDatePickerRange {
    display: flex;
}

.react-datepicker-wrapper {
    width: 100%;
}

.fieldDatePicker {
    width: 100%;
    border: 0.5px solid #4F4F4F;
    padding: 7.5px 8px;
    box-sizing: border-box;
    outline: none;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 12px;
    color: #000000;
    height: 32px;
}
.react-datepicker-wrapper:first-of-type input {
    border-right: none;
}

.fieldDatePicker::placeholder {
    font-size: 13px;
}

.react-datepicker-wrapper input::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 12px;
    color: #000000;
    opacity: 0.5;
}

.fieldset {
    display: flex;
    flex-direction: column;
}

.fieldset__label {
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 12px;
    color: #333333;
}

.react-datepicker-popper {
    z-index: 2;
}

.fieldDatePickerActive { 
    width: 100%;
    border: 2px solid #1E6EBE;
    padding: 7.5px 8px;
    height: 32px;
    box-sizing: border-box;
    outline: none;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 12px;
    color: #000000;
}