.section {
    height: 374px;
    width: 100%;
    background-image: url("../../../public/assets/profile_dashboard_background.png");
    background-size: cover;
    padding: 40px 30px;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}

.card {
    width: 360px;
    height: 153px;
    background-color: #E8F0F8;
    border-radius: 20px;
    padding: 13px 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card__title {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    color: #333333;
}

.card__value {
    height: fit-content;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
    color: #005F78;
    display: flex;
}

.card__deviation {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    color: #005F78;
}

.card__increment,
.card__decrement {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
}

.card__increment {
    color: #05BF01;
}

.card__decrement {
    color: #F21111;
}

.datepicker__headline {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 120%;
    color: #FFFFFF;
    margin-bottom: 21px;
}

.datepicker__btn {
    width: calc(100% - 20px);
    height: 40px;
    background: #FFFFFF;
    border: none;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 23px;
    cursor: pointer;
    text-align: center;
    outline: none;
    caret-color: transparent;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    padding: 9px 14px;
}

.datepicker__btn-text {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
}

.datepicker__btns-group {
    display: flex;
    justify-content: space-between;
    width: 360px;
}

.block {
    display: flex;
    flex-direction: column;
}

.card__value-wrapper {
    display: flex;
    justify-content: space-between;
}
