.modal__switcherWrapper {
    display: flex;
    align-items: center;
}
.modal__inputsWrapper {
    margin: 10px 0 30px 0;
}
.modal__inputBlock {
    display: flex;
    flex-direction: column;
    row-gap: 1px;
}
.modal__inputLabel {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #000000;
}

.modal__input {
    padding: 15px 16px;
    background: #F6F6F6;
    border: none;
    outline: none;
    border: 1px solid #F6F6F6;
}

.modal__input_error {
    border: 1px solid #FF3B30;
}

.modal__error {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #FF3B30;
}

.modal__btnsWrapper {
    display: flex;
    column-gap: 16px;
}

.modal__btn {
    width: 250px;
    height: 55px;
    background: #1E6EBE;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    cursor: pointer;
}

.modal__btn[disabled]{
    cursor: default;
    background-color: rgba(30, 110, 190, 0.25);
}

.selectForm {
    height: 47px !important;
    width: 390px;
    border: none !important;
    border-radius: 0 !important;
    background-color: #F6F6F6 !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 16px !important;
    color: #000000 !important;
}
.modal__input:-webkit-autofill,
.modal__input:-webkit-autofill:hover, 
.modal__input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #F6F6F6 inset;
}
