.wrapperPages {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 800px;
    margin-top: 120px;
}


.item {
    width: 400px;
    height: 110px;
    display: flex;
    margin: 20px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #BFD7DD;
    color: #333;
    text-decoration: none;
    cursor: pointer;
}

.itemDisabled {
    width: 300px;
    height: 110px;
    display: flex;
    margin: 20px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #a0a4a54b;
    color: #333;
    text-decoration: none;
}

.item p {
    margin-bottom: 10px;
}

.itemDisabled p {
    margin-bottom: 10px;
}
