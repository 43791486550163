.containerRoutes {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    color: #1E6EBE;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    margin-top: 20px;
}

.containerRoutes span:last-child {
    color: #030DFF;
    cursor: pointer;
}

.containerHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
}

.btns {
    display: flex;
    height: 40px;
    width: 60%;
    margin-left: 161px;
}

.btns button {
    margin-left: 20px;
    font-weight: 700;
    font-size: 13px;
    line-height: 130%;
    color: #FFFFFF;
    text-transform: none;
    padding: 10px 20px;
    border-radius: 0;
    background-color: #1E6EBE;
    width: 200px;
}

.name {
    width: 40%;
    display: flex;
    align-items: center;
}

.name span {
    font-weight: 700;
    font-size: 27px;
    line-height: 100%;
}

.containerComponent {
    width: 588px;
    height: fit-content;
    border: 1px solid rgba(30, 110, 190, 0.1);
    box-shadow: 0px 4px 4px #aacaeb;
    margin-bottom: 25px;
    padding: 20px;
}

.generalName {
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    text-transform: uppercase;
    margin-bottom: 16px;
}

.headline {
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 18px;
    line-height: 100%;
    margin-right: 10px;
    min-width: 50%;
}

.about {
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
}

.aboutColor {
    margin-bottom: 16px;
    color: #05BF01;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
}

.about:last-child {
    margin-bottom: 0px;
}

.flex {
    display: flex;
    margin-bottom: 16px;
}

.flex div {
    margin-right: 50px;
}

.registrationFlex {
    display: flex;
    margin-bottom: 16px;
}

.registrationFlex div:nth-child(1) {
    margin-right: 33px;
}

.registrationFlex span:nth-child(1) {
    margin-right: 71px;
}

.aboutReg {
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
}

.headlineReg {
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: rgba(0, 0, 0, 1);
}

.fondFlex {
    display: flex;
    margin-bottom: 16px;
}

.fondFlex:last-child {
    margin-bottom: 0;
}

.fondFlex div:nth-child(1) {
    width: 52%;
}

.containerComponents {
    display: flex;
    justify-content: space-between;
}


.bigRormFlex {
    width: 50%;
}

.bigFormFlexHr {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bigFormFlexHr hr {
    width: 100%;
    height: 0px;
    border: 1px solid #005F78;
}

.bigFormFlexSubs {
    width: 320px;
    margin-right: 60px !important;
}

.noData {
    color: rgba(0, 0, 0, 0.5);
}

.containerShortForm {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.typeBtn {
    align-self: flex-end;
    padding: 5px 10px;
    width: fit-content;
    height: 35px;
    background-color: transparent;
    border: 1px solid #1E6EBE;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    cursor: pointer;
    margin-bottom: 32px;
}

.typeBtn:hover {
    background-color: #1E6EBE;
    color: #fff;
}

.link {
    color: #030DFF;

}

.link:hover {
    cursor: pointer;
    text-decoration: underline;
}
.btn {
    color: #fff;
    text-decoration: none;
}