.section {
    padding: 35px 20px;
    border: 1px solid rgba(30, 110, 190, 0.1);
    box-shadow: 0px 4px 8px rgba(30, 110, 190, 0.25);
    display: flex;
    flex-direction: column;
    row-gap: 40px;
}

.section__header {
    display: flex;
    justify-content: space-between;
}

.section__headlineContainer {
    display: flex;
    align-items: center;
    column-gap: 20px;
}

.section__icon {
    height: 40px;
    width: 40px;
    border: 2px solid black;
    border-radius: 50%;
}

.section__headline {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    text-transform: uppercase;
    color: #000000;
}

.section__closeButton {
    height: 30px;
    width: 30px;
    background-color: transparent;
    border: none;
    border-radius: 50%;
    cursor: pointer;

}

.section__closeButton:hover {
    background-color: rgb(225, 225, 225);
}

.section__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 20px;
}

.section__contentBlock {
    display: flex;
}

.profile__fieldName {
    flex: 1;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    color: #000000;
}

.profile__fieldValue {
    flex: 1;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
}

.section__editButton {
    padding: 16px 0;
    background: #1E6EBE;
    width: 250px;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    cursor: pointer;
}

.section__editButton:hover {
    background-color: #6997C6;
}

.wr {
    display: flex;
    flex-direction: column;
    row-gap: 22px;
}