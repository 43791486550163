.header {
    padding: 10px;
    width: fit-content;
    background: rgba(30, 110, 190, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
}

.form {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
}

.wrapperInput {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-right: 45px;
}

.wrapperInput:nth-child(3) {
    margin-right: 0px;
}

.wrapperInput:nth-child(4) {
    margin-top: 24px;
}

.wrapperInput:nth-child(5) {
    margin-top: 24px;
}

.wrapperInput input {
    width: 370px;
    height: 50px;
    outline: none;
    padding: 14px 12px;
    border: 0.5px solid #000000;
    border-radius: 0;
    font-size: 16px;
}

.wrapperInput input::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: #000000;
    opacity: 0.5;
}

.wrapperInput span {
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    color: #333333;
}

.wrapperInput:nth-child(3) span {
    margin-top: -3px;
}

.wrapperDate {
    display: flex;
}

.wrapperDate input {
    width: 185px;
}

.MuiSelect-icon {
    transform: rotate(-90deg);
}

.selectForm {
    width: 370px;
    height: 52px !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    color: '#333333' !important;
    border-radius: 0 !important;

}

.selectForm div {
    background-color: white;
}

.wrapperBtns {
    display: flex;
    margin-top: 24px;
}

.wrapperBtns button {
    width: 250px;
    height: 55px;
    border-radius: 0;
    text-transform: none;
    font-weight: 700;
    font-size: 16px;
}

.wrapperBtns button:last-child {
    margin-left: 21px;
}

.placeholderSelect {
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    opacity: 0.5;
}

.wrapperInput input:-webkit-autofill,
.wrapperInput input:-webkit-autofill:hover,
.wrapperInput input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.wrapperBtns button:disabled {
    background-color: #1976d2;
    color: #fff;
}

.datepicker::placeholder {
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 120% !important;
    color: #000000 !important;
    opacity: 0.5 !important;
}