.MuiOutlinedInput-notchedOutline {
    border-radius: 0px !important;
}

.fieldset__label {
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 12px;
    color: #333333;
}

.MuiAutocompleteOption {
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
}

.MuiAutocompleteOption:hover {
    background-color: rgba(229, 229, 229, 0.382) !important;
}
