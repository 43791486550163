.modal__wrapper {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    position: relative;
    padding: 73px 73px 39px 73px;
    width: 432px;
    background-color: #fff;
    vertical-align: baseline;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    row-gap: 25px;
}

.modal__closeBtn {
    position: absolute;
    top: 25px;
    right: 25px;
    height: 30px;
    width: 30px;
    background-color: transparent;
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal__closeBtn:hover {
    cursor: pointer;
    background-color: rgb(236, 236, 236);
}

.modal__headline {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #333333;
}

.modal__btn {
    width: 250px;
    height: 55px;
    background: #1E6EBE;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}

.modal__btn:hover {
    cursor: pointer;
}