.table__header {
    background: rgba(30, 110, 190, 0.1);
    position: relative;
}
.table__cell_header{
    color: #1E6EBE;
}
.status {
    display: block;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: #B1B2B1;
}

.status_active {
    background-color: #1E6EBE;
}

.tools__wraper {
    position: relative;
    right: -65px;
}

.tools__btn{
    display: flex;
    justify-content: center;
    align-items: top;
    cursor: pointer;
    font-size: 18px;
    padding: 5px;
    font-weight: bold;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: none;
    background-color: inherit;
}

.tools__btn:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.tools__popupBlock {
    position: absolute;
    top: -44px;
    left: -230px;
    width: 230px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
}

.tools__popupBlock button {
    display: flex;
    align-items: center;
}

.tools__popupBlock img {
    margin-right: 5px;
}

.tools__popupBlock svg {
    margin-right: 5px;
}

.tools__popupBtn {
    background-color: transparent;
    border: none;
    padding: 10px 20px;
}

.tools__popupBtn:hover {
    background-color: rgba(204, 223, 228, 0.2);
    cursor: pointer;
}