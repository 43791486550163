.modal__wrapper {
    height: 100vh;
    display: flex;
    align-items: center;
}

.modal {
    padding: 35px;
    width: 1300px;
    background-color: #fff;
    vertical-align: baseline;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.modal__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.modal__headline {
    text-transform: uppercase;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
}

.modal__closeBtn {
    height: 30px;
    width: 30px;
    background-color: transparent;
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal__closeBtn:hover {
    cursor: pointer;
    background-color: rgb(236, 236, 236);
}