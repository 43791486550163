.searchBar {
  display: flex;
  gap: 21px;
}

.query {
  display: flex;
  align-items: center;
  flex: 1;
  gap: 17.31px;
  padding: 8px 10.82px 7px 15.14px;
  border: 0.7px solid #4F4F4F;
}

.queryField {
  flex: 1;
}

.queryField :global(.MuiInputBase-input) {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 22px !important;
  line-height: 120% !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #333333 !important;
}

.queryField :global(.MuiInputBase-input),
.queryField :global(.MuiOutlinedInput-notchedOutline) {
  border: none !important;
}

.queryField input:-webkit-autofill,
.queryField input:-webkit-autofill:hover, 
.queryField input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}


.querySearch {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: flex-end;
  border-radius: 0px !important;
  width: 160.07px;
  height: 55px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px !important;
  line-height: 24px;
  color: #FFFFFF !important;
  background: #1E6EBE !important;
  text-transform: none !important;
}

.filter {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px !important;
  line-height: 24px;
  width: 250px;
  border-radius: 0 !important;
  border: 0.7px solid #333333 !important;
  color: #333333 !important;
  background: #FFFFFF !important;
  text-transform: none !important;
}

.filterActive {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px !important;
  line-height: 24px;
  width: 250px;
  border-radius: 0 !important;
  border: 2px solid #1E6EBE !important;
  color: #333333 !important;
  background: #FFFFFF !important;
  text-transform: none !important;
}
