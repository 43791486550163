.info-text {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #333333;
}

.base__headline {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 130%;
  color: #000000;
}

.contact {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #333333;
}

/* Section */
.section {
  padding: 50px 0;
}

.section__main {
  padding-top: 140px;
  background-image: url('/public/assets/background.jpg');
  background-size: 100% 100%;
  height: 541px;
  display: flex;
  max-width: 1300px;
  margin: 0 auto;
}

.section__headline {
  font-style: normal;
  font-weight: 600;
  font-size: 47px;
  line-height: 61px;
  color: #005f78;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  text-align: center;
}

.section__headline::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #000000;
}

.section__headline:not(:empty)::after {
  margin-left: 1em;
}

.section__headline::after {
  content: '';
  display: block;
  height: 2px;
  background-color: #005f78;
}

/* Amount */
.amount__wrapper::before {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background-color: #005f78;
}

.amount {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 54px;
  color: #333333;
}

.amount__subline {
  /* font-family: 'Roboto'; */

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #767676;
}

/* Block */
.block {
  background-color: #005f78b3;
  width: 741px;
  padding: 25px 40px;
}

.block__headline {
  font-style: normal;
  font-weight: 600;
  font-size: 55px;
  line-height: 66px;
  align-items: center;
  color: #ffffff;
}

.block__subheadline {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: #ffffff;
}

/* Intelligence */
.intelligence__icon {
  height: 80px;
  width: 80px;
}

.intelligence__text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.intelligence__link {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #005f78;
  text-decoration: none;
}

.intelligence__link:hover {
  text-decoration: underline;
}

/* Instruction */
.inctructions__text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.inctructions__link {
  color: #000000;
}

/* Chart */
.chart__responsive-container {
  width: 80% !important;
  height: 380px !important;
}

.chart__area {
  width: 500px;
  height: 500px;
  top: 0;
  right: 30px;
  left: 0;
  bottom: 0;
}

.chart__container {
  box-shadow: 0px 4px 8px #1e6ebe40;
  padding: 20px;
  border-radius: 8px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.chart__headline {
  min-height: 50px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #0c2942;
}

/* Accordion */
.accordion__summary,
.accordion__details {
  background-color: #1e6ebe0d !important;
}

.accordion__typography_head,
.accordion__typography_body {
  font-family: 'Roboto', sans-serif !important;
  font-style: normal !important;
}

.accordion__typography_head {
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 26px !important;
  color: #333333 !important;
}

.accordion__typography_body {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #000000 !important;
}

.accordion__typography-paragraph {
  margin-bottom: 20px;
}

.accordion__button {
  width: 250px;
  height: 55px;
  background: #1e6ebe;
  border: none;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
}

.wrContentStat {
  display: flex;
  justify-content: space-between;
}

.wrContentDiagram {
  display: flex;
  column-gap: 20px;
}

.wrContentInfo {
  display: flex;
  column-gap: 20px;
  flex-wrap: wrap;
}

.blockContentStat {
  width: 75%;
}

.blockContentStatImg {
  width: 25%;
  display: flex;
  justify-content: flex-end;
}

.wrContent {
  display: flex
}

.blockContent {
  width: 50%;
}

.blockContentHalf {
  width: calc(50% - 20px / 2);
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.rowWr {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.infoSvg {
  height: 80px;
  width: 80px;
}
.diagram_text {
  text-align: center;
}