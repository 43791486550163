.modal__container {
    height: calc(100vh - 130px - 213px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 32px;
}

.modal {
    padding: 35px 40px;
    width: 590px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    row-gap: 17px;
    column-gap: 4px;
}

.modal__status {
    font-style: normal;
    font-weight: 800;
    font-size: 130px;
    line-height: 133px;   
    color: #1E6EBE;
    text-align: center;
}

.modal__headline {
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 24px;    
    color: #4D4D4F;
    text-align: center;
}

.modal__subheadline {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;     
    color: #000000;
    text-align: center;
}

.modal__btnsWrapper {
    margin: 54px;
    display: flex;
    justify-content: space-between;
    column-gap: 40px;
}

.modal__btn {
    width: 250px;
    height: 55px;
    background-color: #1E6EBE;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    cursor: pointer;
}