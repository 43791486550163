.btn {
  position: fixed;
  bottom: 30px;
  right: 30px;
  padding: 10px;
  height: 39px;
  width: 86px;
  background-color: #e8f0f8;
  border: none;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  color: #1e6ebe;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn_disabled {
  display: none;
}