.containerFilter {
    width: 270px;
    background: #F9FBFD;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.containerHeader {
    display: flex;
    justify-content: space-between;
}

.header {
    padding: 0 14px 0 16px;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
form {
    box-sizing: border-box;
}

.textMain {
    background: rgba(30, 110, 190, 0.25);
    font-weight: 700;
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 5px;
    text-transform: uppercase;
    width: fit-content;
}

.btnSubmit {
    width: 100% !important;
    padding: 8px !important;
    text-transform: none !important;
    margin-bottom: 8px !important;
    margin-top: 16px !important;
    border-radius: 0 !important;
    background: #1E6EBE;
    color: #FFFFFF;
}

.btnReset {
    text-transform: none !important;
    font-size: 13.5px !important;
    line-height: 130% !important;
    font-weight: 600 !important;
    padding: 10px 15.5px !important;
    width: 100% !important;
    border-radius: 0 !important;
}

.btnClose {
    cursor: pointer;
}

.textMainReset {
    background: rgba(30, 110, 190, 0.25);
    font-weight: 700;
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 5px;
    text-transform: uppercase;
    width: fit-content;
    margin-top: 0px;
}

.form {
    height: 100%;
}

.form__fieldsGroupWrapper {
    padding: 10px 15px 0 20px;
    height: calc(100% - 180px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    row-gap: 25px;
}

.form__fieldsGroupWrapper::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
}

.form__fieldsGroupWrapper::-webkit-scrollbar-thumb {
    background: #767676;
    border-radius: 5px;
    box-shadow: inset 1px 1px 10px #f3faf7;
}

.form__fieldsGroupWrapper::-webkit-scrollbar-thumb:hover {
    background-color: #253861;
}

.form__fieldsGroup {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.form__fields {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.form__btnsGroup {
    padding: 10px 20px 10px 20px;
    z-index: 1;
    width: 270px;
    bottom: 0px;
    background: #F9FBFD;
    height: fit-content;
}
