.section {
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.containerBreadCrumbs {
    margin: 20px 0;
}

.cont {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 8px rgba(30, 110, 190, 0.25);
    border-radius: 4px;
    max-height: fit-content;
    height: fit-content;
}
.filterTable__container {
    display: flex;
    column-gap: 20px;
    max-width: 1300px !important;
}

.popup__container {
    position: relative;
}

.popup__btn {
    display: flex;
    justify-content: center;
    align-items: top;
    cursor: pointer;
    font-size: 18px;
    padding: 5px;
    font-weight: bold;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: none;
    background-color: inherit;
}

.popup__btn:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.popup__modal {
    position: absolute;
    left: -135px;
    bottom: -20px;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
}

.popup__modalBtn {
    padding: 5px 15px;
    background-color: transparent;
    border: none;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    color: #333333;
}

.popup__modalBtn:hover {
    background-color: rgba(204, 223, 228, 0.2);
    cursor: pointer;
}

.table__head {
    background: rgba(30, 110, 190, 0.1);
}

.table__head b {
    color: #1E6EBE;
}
.tableLabel {
    color: #1E6EBE !important;
}
.table__footer {
    display: flex;
    align-items: center;
    column-gap: 206px;
    padding: 20px;
}
.table__saveBtn {
    height: 39px;
    width: 200px;
    background-color: #1E6EBE;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
    color: #FFFFFF;
    cursor: pointer
}
.cell_checkbox {
    width: 40px !important;
}