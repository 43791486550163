.footer {
  background-color: #e8f0f8;
  padding: 50px 0;
  height: 213px;
  min-width: 1300px;
  margin: 0 auto;
}
.wr {
  display: flex
}
.block {
  width: 50%;
}
.footer__link {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  text-decoration-line: underline;
  color: #000000;
}
.footer__contact-header {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.footer__contact {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #000000;
}
