.section {
    display: flex;
    flex-direction: column;
    row-gap: 50px;
}

.section__btn {
    width: 250px;
    height: 55px;
    background-color: #1E6EBE;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    cursor: pointer;
}

.section__btn:disabled {
    background-color: #1e6ebe73;
    cursor: initial;
}