.containerInputsRange {
    display: flex;
}

.fieldset__input {
    width: 50%;
    font-weight: 400;
    font-size: 13px;
    line-height: 120%;
    background: #FFFFFF;
    border: 0.5px solid #4F4F4F;
    padding: 7.2px 8px;
    box-sizing: border-box;
    outline: none;
    height: 32px;
}

.fieldset__input:first-of-type {
    border-right: none;
}

.fieldset__input::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 12px;
    color: #000000;
    opacity: 0.5;
}

.fieldset {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
}

.fieldset__label {
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 12px;
    color: #333333;
}