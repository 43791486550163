.content__container {
    display: flex;
    flex-direction: column;
}

.filterTable__container {
    display: flex;
    column-gap: 30px;
    margin-bottom: 50px;
}


.table__container {
    display: flex;
    box-shadow: 0px 4px 8px rgba(30, 110, 190, 0.25);
}

.footer {
    display: flex;
    padding: 20px;
}

.footer__btnsContainer {
    display: flex;
    column-gap: 10px;
}

.footer__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 39px;
    background: #1e6ebe;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
    color: #ffffff;
    cursor: pointer;
}
.reset__btn {
    padding: 15px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: 39px;
    background: transparent;
    border: 1px solid #be361e;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
    color: #be361e;
}
.reset__btn:hover {
    background: #be361e;
    color: #ffffff;
    cursor: pointer;
}