.section {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
.form {
    padding: 8px;
    height: 70px;
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 18px;
    border: 0.7px solid #4F4F4F;
    margin-top: 16px;
    margin-bottom: 25px;
}

.form__icon {
    flex: 1;
}

.form__input {
    flex: 4;
    padding: 10px 0;
    border: none;
    outline: none;
    -webkit-text-fill-color: #000;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    color: #333333;
}

.form__input::placeholder {
    color: #333333;
    opacity: 0.5;
}

.form__btn {
    height: 100%;
    background-color: #1E6EBE;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    cursor: pointer;
    width: 160px;
}

.tableContainer {
    border-radius: 0px !important;
    box-shadow: none !important;
}

.tableHeader {
    background: rgba(30, 110, 190, 0.1);
}

.tableCellHead {
    border: none !important;
    color: #1E6EBE !important;
}

.headCell {
    border-bottom: 0.5px solid #333333 !important;
}

.headCellDate {
    border-bottom: 0.5px solid #333333 !important;
    width: 230px;
}

.tableRow {
    height: 46px;
}

.pagination {
    margin-top: 40px;
    margin-bottom: 40px;
}
