* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul,
ol {
  list-style: none;
}

html {
  scroll-behavior: smooth;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* css variables start */
:root {
  --primary-color: #ffffff;
  --header-height: 130px;
}
/* css variables end */

/* common sttyles start */
body {
  font-family: 'Roboto', sans-serif;
}
.pointer {
  cursor: pointer;
}
.flex-distribution-edge {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.createButton {
  margin-top: 10px;
}

.main-content {
  max-width: 1300px;
  margin: 0 auto;
  margin-top: var(--header-height);
}
/* common sttyles end */

.noRole {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  font-size: 18px;
}

button:disabled {
  cursor: default !important;
  background-color: rgba(30, 110, 190, 0.25) !important;
  color: #FFFFFF !important;
}

.MuiSnackbar-root {
  top: 120px !important;
}

.MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  min-width: 0 !important;
}