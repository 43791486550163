.title {
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
}
.containerUp {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.containerBottom {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
  
  .item {
  width: 300px;
  height: 110px;
  display: flex;
  margin: 20px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #BFD7DD;
  color: #333;
  text-decoration: none;
  cursor: pointer;
  }

  .item p{
    margin-bottom: 10px;
    }
  
  .item:nth-child(1),
  .item:nth-child(2),
  .item:nth-child(3) {
  flex-basis: calc((100% - 20px) / 3);
  }
  
  .item:nth-child(4),
  .item:nth-child(5) {
  flex-basis: calc((100% - 10px) / 2);
  }

  .itemDisabled {
    width: 300px;
    height: 110px;
    display: flex;
    margin: 20px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #a0a4a54b;
    color: #333;
    text-decoration: none;
  }

  .itemDisabled p{
    margin-bottom: 10px;
    }
  
  .itemDisabled:nth-child(1),
  .itemDisabled:nth-child(2),
  .itemDisabled:nth-child(3) {
  flex-basis: calc((100% - 20px) / 3);
  }
  
  .itemDisabled:nth-child(4),
  .itemDisabled:nth-child(5) {
  flex-basis: calc((100% - 10px) / 2);
  }
  