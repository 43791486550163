.header {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    height: var(--header-height);
    position: fixed !important;
    box-shadow: none !important;
    background-color: var(--primary-color) !important;
}

.header_home {
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(238, 236, 236, 0) 88.58%) !important;
}

.toolbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 24px;
    padding-left: 0 !important;
}

.linkFlex {
    display: flex;
}

.userInfo {
    display: flex;
    align-items: center;
    margin-left: 32px;
}

nav {
    display: flex;
    align-items: center;
}

.buttonWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 25px;
}

.buttonLogout {
    text-decoration: none;
    margin-left: 20px;
    display: flex;
    cursor: pointer;
}

.buttonLogout .link {
    display: flex;
}

.link {
    text-decoration: none;
    margin-left: 25px;
    font-weight: 700;
    font-size: 18px;
    color: #333333;
    white-space: nowrap;
}

.login {
    display: flex;
    align-items: center;
    margin-left: 15px;
    font-weight: 700;
    font-size: 16px;
    color: #333333;
}

.logout {
    margin-left: 15px;
    font-weight: 400;
    font-size: 16px;
    color: #333333;
    text-align: left;
}

.linkMenu {
    display: flex;
    text-decoration: none;
    color: #333333;
    width: 100%;
}

.popperRing {
    width: 370px;
    height: fit-content;
    min-height: 50px;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    padding: 19px 16px;
}

.btnRing {
    margin-right: 0px !important;
}

.headerPopperRing {
    display: flex;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    align-items: center;
    color: #333333;
    opacity: 0.5;
    margin-bottom: 12px;
    justify-content: space-between;
}

.itemPopperRing {
    display: flex;
    justify-content: space-between;
    padding: 13px 0px;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: #333333;
}

.itemPopperRing span:last-child {
    opacity: 0.59;
}

.loadingDates {
    width: 80px;
    margin: 0 auto;
}
