.radioGroup__wrapper {
    display: flex !important;
    column-gap: 20px;
}

.createBtn {
    width: 250px;
    height: 55px;
    background: #1E6EBE;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-top: 45px;
}

.createBtn:hover {
    cursor: pointer;
}

.row__circle {
    display: block;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: #B1B2B1;
}

.row__circle_active {
    background-color: #1E6EBE;
}

.tools__wraper {
    position: relative;
    right: -130px;
    bottom: 3px;
}

.tools__btn {
    display: flex;
    justify-content: center;
    cursor: pointer;
    font-size: 18px;
    padding: 5px;
    font-weight: bold;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: none;
    background-color: inherit;
}

.tools__btn:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.tools__popupBlock {
    position: absolute;
    left: -168px;
    top: 44px;
    width: 230px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    z-index: 1;
}

.tools__popupBlock button{
    display: flex;
    align-items: center;
}

.tools__popupBlock img{
    margin-right: 5px;
}

.tools__popupBtn {
    background-color: transparent;
    border: none;
    padding: 10px 20px;
}

.tools__popupBtn:hover {
    background-color: rgba(204, 223, 228, 0.2);
    cursor: pointer;
}
.tableHeader {
    background: rgba(30, 110, 190, 0.1);
    position: relative;
}
.tableHeader b {
    color: #1E6EBE;
}
.head__wrapper {
    display: flex;
}
.containerFooter {
    display: flex;
    text-align: center;
    margin-bottom: 30px;
}
.pagination {
    margin-left: 250px;
    margin-top: 50px;
}
.head__search {
    margin-bottom: 20px;
}
.containerTable {
    margin-top: 15px;
}
