.selectControl>div {
    padding-right: 0;
}

.selectControl {
    width: 272px;
    margin-left: auto !important;
    display: flex !important;
    justify-content: flex-end;
}

.select {
    padding-right: 0px;
}

.select fieldset {
    border: none;
}

.select .MuiSelect-select {
    padding-right: 0px;
}

.menuItem {
    background-color: inherit;
}

.menuItemActive {
    background-color: rgba(25, 118, 210, 0.08) !important;
}

.icon {
    position: absolute !important;
    right: 0 !important;
    pointer-events: none !important;
}
