.form_radio_btn {
    width: 50%;
}
.form_radio_btn input[type=radio] {
	display: none;
}

.btn {
	display: flex;
    justify-content: center;
    align-items: center;
	cursor: pointer;
	width: 100%;
    /* height: 30px; */
    padding: 8.9px 0;
	font-weight: 400;
    font-size: 13px;
    line-height: 120%;
	background: #FFFFFF;
    border: 0.5px solid #4F4F4F;
	user-select: none;
    color: #000000;
    opacity: 0.5;
    height: 32px;
}

.checkedBtn {
    opacity: 1;
    font-weight: 500;
    border: 2px solid #1E6EBE;
    display: flex;
    justify-content: center;
    align-items: center;
	cursor: pointer;
	width: 100%;
    padding: 8.9px 0;
    height: 32px;
    font-size: 13px;
    line-height: 120%;
	background: #FFFFFF;
    user-select: none;
    color: #000000;
}
 
/* Hover */
.form_radio_btn label:hover {
	color: #666;
}
 
/* Disabled */
.form_radio_btn input[type=radio]:disabled + label {
	background: #efefef;
	color: #666;
}



.containerRadio {
    display: flex;
    width: 100%;
    /* margin-bottom: 14px; */
}
.fieldset {
    display: flex;
    flex-direction: column;
}

.fieldset__label {
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 12px;
    color: #333333;
}